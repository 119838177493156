import PropTypes from 'prop-types';
import React from "react"
import { formatMoney } from './Step1';

import smallHammockDude from '../../../../content/assets/small-hammock-dude.png'
import chatBubbleDude from '../../../../content/assets/chat-bubble-dude.png'

import level0Img from '../../../../content/assets/calculator-results/level0.png'
import level1Img from '../../../../content/assets/calculator-results/level1.png'
import level2Img from '../../../../content/assets/calculator-results/level2.png'
import level3Img from '../../../../content/assets/calculator-results/level3.png'


class Step5 extends React.Component {
  static propTypes = {
    calculatorContext: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)

    let {
      monthlyPaycheckAfterTaxes,
      monthlyGrossRevenue,
      salaryGoal,
      businessExpenses,
      monthlyGrossRevenueTaxes,
      runway,
      runwayString
    } = this.props.calculatorContext

    const runrate = monthlyPaycheckAfterTaxes + businessExpenses


    this.runwayLevels = {
      0: {
        image: level0Img,
        description: "Time to hustle! You don't have enough money to pay yourself next month.",
        busy: "Consider raising your rates to fit your lifestyle better. Think about different ways you could implement value-based pricing, or explore additional revenue streams.",
        slow: "You've got this! Try reaching out to past clients, ask for referrals, or start attending local networking events. Look for ways to connect with busy freelancers who may have overflow work. Remember; it takes time and effort to build up your freelance network, so keep at it!",
        color: "red",
      },
      1: {
        image: level1Img,
        description: `You've got this! You have enough money to pay yourself ${formatMoney(runrate)} for the next ${runwayString}.`,
        busy: "Keep taking on new work!  Your Runway will grow faster if you're making more money than you need every month.",
        slow: "Try reaching out to past clients, ask for referrals, or start attending local networking events.",
        color: "yellow",
      },
      2: {
        image: level2Img,
        description: `Nice work! You have enough money to pay yourself ${formatMoney(runrate)} for the next ${runwayString}.`,
        busy: "You've earned a vacation - consider taking some time off to recharge and reflect on your success.",
        slow: "Feel comfortable taking some time to relax and refocus. You could work on a new side project or invest some time into learning new skills that will increase the value of your overall offering.",
        color: "teal",
      },
      3: {
        image: level3Img,
        description: `Wow! You have enough money to pay yourself ${formatMoney(runrate)} for the next ${runwayString}.`,
        busy: "It's time to start thinking about the future of your business. Do all of your current projects align with your long term career goals? If not, consider dropping projects that you are no longer invested in.",
        slow: "Enjoy the freelance lifestyle you've worked so hard achieve! Take an extended vacation, work on a side project, or invest some time into learning new skills.",
        color: "green",
      },
    }
  }


  calculateRunwayLevel = (runway) => {
    if (runway >= 0 && runway <= 1) {
      return 0
    }
    else if (runway >= 1 && runway <= 4) {
        return 1
    }
    else if (runway >= 4 && runway <= 8) {
        return 2
    }
    else if (runway >= 8){
        return 3
    }
  }

  render() {
    let {
      monthlyPaycheckAfterTaxes,
      monthlyGrossRevenue,
      salaryGoal,
      businessExpenses,
      monthlyGrossRevenueTaxes,
      runway,
      runwayString
    } = this.props.calculatorContext

    const runrate = monthlyPaycheckAfterTaxes + businessExpenses
    const taxBurden = monthlyGrossRevenue - monthlyPaycheckAfterTaxes

    let runwayLevel = this.calculateRunwayLevel(runway)
    let level = this.runwayLevels[runwayLevel]

    return (
      <div className={`step step5 lifestyle-calculator-results ${level.color}`}>
        <div className="row">
          <div className="col-10 offset-1">
            <div className="step-description">
              <h6 className="section-title">Step {this.props.calculatorContext.step} of 5</h6>
              <h2>Your Runway is <span className={level.color}>{runwayString}</span></h2>
              <p className="runway-description">{level.description}</p>
            </div>
          </div>
        </div>

        <div className="row calculator-section results grey">
          <div className="col-10 offset-1 col-md-8 offset-md-2">
            <h6>If You're Busy</h6>
            <p>{level.busy}</p>

            <h6>If You're Slow</h6>
            <p>{level.slow}</p>
          </div>
          <div className="col-8 offset-2">
            <img className="small-hammock-dude" src={level.image} />
          </div>
        </div>

        <div className="row calculator-section lifestyle-calculator-summary primary">
          <div className="col-10 offset-1 text-center">
            <h4>You'll need to earn <strong>{formatMoney(runrate + taxBurden)} every month</strong> to cover your <strong>{formatMoney(monthlyPaycheckAfterTaxes)} monthly paycheck</strong>, <strong>{formatMoney(businessExpenses)} in monthly business expenses</strong>, and around <strong>{formatMoney(monthlyGrossRevenueTaxes)} for taxes</strong>.</h4>
          </div>
        </div>
      </div>
    )
  }
}

export default Step5


