import PropTypes from 'prop-types';
import React from "react"

import classNames from 'classnames';

import { formatMoney } from './Step1';


class Step2 extends React.Component {
  static propTypes = {
    handlePaycheckDefinition: PropTypes.func.isRequired,
    handlePersonalExpenses: PropTypes.func.isRequired,
    handleSalaryGoal: PropTypes.func.isRequired,
    updateSalaryGoal: PropTypes.func.isRequired,
    salaryToPaycheckAfterTaxes: PropTypes.func.isRequired,
    calculatorContext: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)
    this.salaryGoal = React.createRef()
    this.personalExpenses = React.createRef()
    this.personalExpensesForm = React.createRef()

    this.state = {
      categories: {
        "idx1": {
          label: "Mortgage or Rent",
          value: 0,
        },
        "idx2": {
          label: "Transportation (bus fare, car payment, etc)",
          value: 0,
        },
        "idx3": {
          label: "Utilities (Phone, Internet, Gas, Electric, etc)",
          value: 0,
        },
        "idx4": {
          label: "Health Insurance",
          value: 0,
        },
        "idx5": {
          label: "Groceries, Eating Out & Coffee Shops",
          value: 0,
        },
        "idx6": {
          label: "Entertainment",
          value: 0,
        },
        "idx7": {
          label: "Student Loans",
          value: 0,
        },
        "idx8": {
          label: "Savings & Retirement",
          value: 0,
        },
        "idx9": {
          label: "Other",
          value: 0,
        },
      }
    }
  }

  componentDidMount() {
  }

  sumExpenses = () => {
    // sum code from https://codeburst.io/javascript-arrays-finding-the-minimum-maximum-sum-average-values-f02f1b0ce332
    return Object.keys(this.state.categories).map(key => {
      return this.state.categories[key].value
    }).reduce((a,b) => a + b, 0)
  }

  handleContinueBtn = (e) => {
    e.preventDefault()
    if (this.props.calculatorContext.paycheckDefinition === 'salary') {
      this.props.handleSalaryGoal(this.salaryGoal.current.value)
    } else {
      this.props.handlePersonalExpenses(this.sumExpenses())
    }
  }

  handleExpenseUpdate = (e) => {
    e.preventDefault()
    let idx = e.target.dataset.idx

    this.setState({
      categories: {
        ...this.state.categories,
        [idx]: {
          label: this.state['categories'][idx].label,
          value: Number(e.target.value),
        }
      }
    })
  }

  handleSalaryUpdate = (e) => {
    this.props.updateSalaryGoal(this.salaryGoal.current.value)
  }

  handleAddCategory = (e) => {
    e.preventDefault()
    let timestamp = Math.round((new Date()).getTime() / 1000)

    this.setState({
      categories: {
        ...this.state.categories,
        [`idx${timestamp}`]: {
          label: '',
          value: 0,
        }
      }
    })
  }

  renderSalaryOption = () => {
    return (
      <div className="row calculator-section grey">
        <div className="col-10 offset-1">
          <div className="col-10 offset-1">
            <p>Knowing your yearly salary goal is a great starting point!</p>
            <p>Below enter your yearly salary goal, and we'll figure out a rough estimate of how much money you would actually be making AFTER TAX. (we're just subtracting 30% out of the salary -- which is a common generic tax estimate).</p>

            <div className="row yearly-salary-form">
              <div className="col-12 col-lg-5 pb-5 pb-lg-0">
                <label htmlFor="salary-goal">Yearly Salary Goal</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">$</div>
                  </div>
                  <input type="number" step="0.01" className="form-control" name="salaryGoal" id="salary-goal" placeholder="0.00" ref={this.salaryGoal} onChange={this.handleSalaryUpdate} defaultValue={this.props.calculatorContext.salaryGoal === 0 ? "" : this.props.calculatorContext.salaryGoal} />
                </div>
              </div>
              <div className="col-12 col-lg-6 offset-lg-1">
                <div>
                  <label>Your Monthly Paycheck After Tax</label>
                  <span className="salary-after-tax">{formatMoney(this.props.salaryToPaycheckAfterTaxes(this.props.calculatorContext.salaryGoal))}/month</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderExpensesOption = () => {
    return (
      <div className="row calculator-section grey">
        <div className="col-12 col-md-10 offset-md-1">
          <div className="row">
            <div className="col-10 offset-1">
              <p>Fill out the Monthly Budget fields below with numbers that reflect your current lifestyle. The closer these numbers are to reality, the happier you will be with the results of this tool. Being off by $1,000/month can really throw a wrench in things down the road when you're trying to book a vacation.</p>

              <form className="form monthly-expenses-form" ref={this.personalExpensesForm}>
                <div className="form-row">
                  <div className="col-6 col-md-8">
                    <strong>Category</strong>
                  </div>
                  <div className="col-6 col-md-4 ml-auto">
                    <strong>Monthly Budget</strong>
                  </div>
                </div>

  

                {Object.keys(this.state.categories).map((key) => {
                  let category = this.state.categories[key]
                  if (category === undefined) {
                    return ""
                  }
                  return (
                    <div className="form-row" key={key}>
                      <div className="col-6 col-md-8">
                        <input className="form-control category-name" data-idx={key} type="text" defaultValue={category.label} placeholder="New Category"></input>
                      </div>

                      <div className="input-group col-6 col-md-4 ml-auto">
                        <div className="input-group-prepend">
                          <div className="input-group-text">$</div>
                        </div>
                        <input type="number" data-idx={key} className="form-control" placeholder="0" onChange={this.handleExpenseUpdate} />
                      </div>
                    </div>
                  );
                })}


                <div className="form-row">
                  <div className="col-2">
                    <a href="#" onClick={this.handleAddCategory}>Add Category</a>
                  </div>
                  <div className="col-4 col-md-6 text-right">
                    <strong>Total:</strong>
                  </div>

                  <div className="col-6 col-md-4">
                    <span className="total">
                      {formatMoney(this.sumExpenses())}
                    </span>
                  </div>
                </div>

              </form>


            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let salaryBtnClassnames = classNames({
      'define-paycheck-btn': true,
      'active': this.props.calculatorContext.paycheckDefinition === 'salary'
    })
    let expensesBtnClassnames = classNames({
      'define-paycheck-btn': true,
      'active': this.props.calculatorContext.paycheckDefinition === 'expenses'
    })

    let paycheckDefinition = this.props.calculatorContext.paycheckDefinition

    return (
      <div className="step step2">
        <div className="row">
          <div className="col-10 offset-1">
            <div className="step-description">
              <h6 className="section-title">Step {this.props.calculatorContext.step} of 5</h6>
              <h2>How Much Should You Pay Yourself?</h2>
              <p>As a freelancer, you should always pay yourself the same amount of money every month. This is probably the most impactful thing we've learned along our journey as freelancers.</p>
              <p>When you pay yourself the same amount of money every month, you'll quickly discover that the good months will keep you covered during the bad months. You'll also be able to figure out your Runway, so you know exactly how long you will survive before you run out of money.</p>
              <p>Below you'll find two options. If you're in a rush or you already know what you want your yearly salary to be, click the "I have a yearly salary goal" button. If you have a few minutes and want to approach things holistically, you'll want to click the "Tally Up My Personal Expenses" button.</p>
            </div>
          </div>

          <div className="col-10 offset-1">
            <div className="row">
              <div className="col-12 col-md-6">
                <a className={salaryBtnClassnames} data-definitionlabel="salary" onClick={this.props.handlePaycheckDefinition} href="#salary-goal">I Have A Yearly Salary Goal</a>
              </div>
              <div className="col-12 col-md-6 pb-5">
                <a className={expensesBtnClassnames} data-definitionlabel="expenses" onClick={this.props.handlePaycheckDefinition} href="#personal-expenses">Tally Up My Personal Expenses</a>
              </div>
            </div>
          </div>

          {paycheckDefinition === 'salary' && this.renderSalaryOption()}
          {paycheckDefinition === 'expenses' && this.renderExpensesOption()}

          {paycheckDefinition !== '' && (
            <div className="col-12 text-center">
              <a className="btn btn-primary continue-btn" href="#" onClick={this.handleContinueBtn}>Next Step: Business Expenses &rarr;</a>
            </div>
          )}

        </div>
      </div>
    )
  }
}

export default Step2


