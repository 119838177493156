import "./FreeconWorkshop.scss"
import React from "react"

import Step1 from './Step1.jsx'
import Step2 from './Step2.jsx'
import Step3 from './Step3.jsx'
import Step4 from './Step4.jsx'
import Step5 from './Step5.jsx'

import SteppedProgressBar from '../SteppedProgressBar/SteppedProgressBar.jsx'
import { navigate } from "gatsby"


class FreelanceLifestyleCalculator extends React.Component {

  constructor(props) {
    super(props);

    this.state =  {
      step: 1,
      paycheckDefinition: '', // salary, expenses
      salaryGoal: 0,

      monthlyPaycheckAfterTaxes: 0,
      personalExpensesTotal: 0,
      businessExpenses: 0,
      bankAccountBalance: 0,
      runwayString: '',
      monthlyGrossRevenueTaxes: 0,
      monthlyGrossRevenue: 0,
      yearlySalary: 0,
    }
  }

  componentDidMount() {

  }

  handlePaycheckDefinition = (e) => {
    e.preventDefault()

    let paycheckDefinition = e.target.dataset.definitionlabel
    this.setState({paycheckDefinition})
  }

  salaryToPaycheckAfterTaxes = (salaryGoal) => {
    const taxRate = 0.3
    const taxBurden = salaryGoal * taxRate
    const salaryAfterTax = salaryGoal - taxBurden
    return salaryAfterTax / 12
  }

  paycheckToGrossRevenue = (paycheck) => {
    const taxRate = 0.3
    const divisor = 1.0 - taxRate

    return paycheck / divisor 
  }

  paycheckToYearlyGrossSalary = (paycheck) => {
    return this.paycheckToGrossRevenue(paycheck) * 12
  }

  handleSalaryGoal = (salaryGoal) => {
    if (salaryGoal === "") {
      return
    }
    let monthlyPaycheckAfterTaxes = this.salaryToPaycheckAfterTaxes(salaryGoal)
    let monthlyGrossRevenue = salaryGoal / 12
    let monthlyGrossRevenueTaxes = monthlyGrossRevenue - monthlyPaycheckAfterTaxes
    this.setState({
      salaryGoal,
      monthlyPaycheckAfterTaxes,
      monthlyGrossRevenue,
      monthlyGrossRevenueTaxes,
      step: 3
    })
    window.scrollTo(0,0);
  }

  handlePersonalExpenses = (personalExpensesTotal) => {
    if (personalExpensesTotal === "") {
      return
    }

    let monthlyGrossRevenue = this.paycheckToGrossRevenue(personalExpensesTotal)
    let monthlyGrossRevenueTaxes = monthlyGrossRevenue - personalExpensesTotal
    this.setState({
      personalExpensesTotal,
      monthlyGrossRevenue,
      monthlyGrossRevenueTaxes,
      monthlyPaycheckAfterTaxes: personalExpensesTotal,
      salaryGoal: this.paycheckToYearlyGrossSalary(personalExpensesTotal),
      step: 3
    })
    window.scrollTo(0,0);
  }

  handleBusinessExpenses = (businessExpenses) => {
    if (businessExpenses === "") {
      return
    }

    this.setState({
      businessExpenses: Number(businessExpenses),
      step: 4
    })
    window.scrollTo(0,0);
  }

  handleBankAccountBalance = (bankAccountBalance) => {
    if (bankAccountBalance === "") {
      return
    }

    const runrate = this.state.monthlyPaycheckAfterTaxes + this.state.businessExpenses
    const runway = bankAccountBalance / runrate
    this.setState({
      bankAccountBalance,
      runway,
      runwayString: `${runway.toFixed(1)} Months`,
      step: 5
    })
    window.scrollTo(0,0);
  }

  handleGetStarted = (e) => {
    // track Get Started
    e.preventDefault()
    this.setState({step: 2})
    window.scrollTo(0,0);
  }

  handleProgressBarClick = (e) => {
    let stepNumber = Number(e.target.dataset.step)
    this.setState({step: stepNumber})
    window.scrollTo(0,0);
  }

  updateSalaryGoal = (salaryGoal) => {
    this.setState({salaryGoal})
  }
  
  renderStep = () => {
    switch(this.state.step) {
      case 1:
        return <Step1 handleGetStarted={this.handleGetStarted}
                      calculatorContext={this.state} />
      case 2:
        return <Step2 handlePaycheckDefinition={this.handlePaycheckDefinition}
                      handleSalaryGoal={this.handleSalaryGoal}
                      handlePersonalExpenses={this.handlePersonalExpenses}
                      salaryToPaycheckAfterTaxes={this.salaryToPaycheckAfterTaxes}
                      updateSalaryGoal={this.updateSalaryGoal}
                      calculatorContext={this.state} />
      case 3:
        return <Step3 handleBusinessExpenses={this.handleBusinessExpenses}
                      calculatorContext={this.state} />
      case 4:
        return <Step4 handleBankAccountBalance={this.handleBankAccountBalance}
                      calculatorContext={this.state} />
      case 5:
        return <Step5 calculatorContext={this.state} />
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 pt-5">
          <div className="freelance-lifestyle-calculator">
            <SteppedProgressBar stepCount={5}
                                currentStep={this.state.step}
                                handleProgressBarClick={this.handleProgressBarClick} />
            {this.renderStep()}
          </div>
        </div>
      </div>
    )
  }
}

export default FreelanceLifestyleCalculator


