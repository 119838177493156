import PropTypes from 'prop-types';
import React from "react"

import classNames from 'classnames';
import FreelanceStarterGuideImage from '../../../../content/assets/app/svg/freelance-starter-guide.svg'



export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + "$" + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

class Step1 extends React.Component {
  static propTypes = {
    handleGetStarted: PropTypes.func.isRequired,
    calculatorContext: PropTypes.object.isRequired
  }

  render() {
    return (
      <div className="step step1">
        <div className="row">
          <div className="body-content col-10 offset-1">
            <div className="step-description">
              <h6 className="section-title">Step {this.props.calculatorContext.step} of 5</h6>
            </div>

            <p>&#128075; Hi Friend!</p>
            <p>We put together this calculator to help you figure out how much money you need to make every month to cover living expenses, business expenses, and taxes.</p>
            <p>Once you know these numbers, you can start to make real business decisions based on your runway and cash on hand. Take that vacation you've been putting off. Take some time off to learn a new skill and improve the value of your offering. Pass on that new client with all the red flags.</p>
          </div>
        </div>

        <div className="row calculator-section lifestyle-calculator-summary primary-gradient">
          <div className="col-10 offset-1 col-lg-6 offset-lg-3 text-center">
            <FreelanceStarterGuideImage className="freelance-starter-guide-image" />
            <h3>Take Your First Step Towards Financial Stability as a Freelancer</h3>
            <a className="btn btn-white" href="#" onClick={this.props.handleGetStarted}>Let's Get Started!</a>
          </div>
        </div>

      </div>
    )
  }
}

export default Step1
