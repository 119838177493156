import "../scss/pages/LifestyleCalculator.scss"
import { Link } from "gatsby"
import Img from 'gatsby-image'

import React from "react"
import SEO from "../components/marketing/seo"
import Header from "../components/marketing/Header/Header.jsx"
import Footer from "../components/marketing/Footer/Footer.jsx"

import FreeconWorkshop from '../components/marketing/FreeconWorkshop/FreeconWorkshop.jsx'
import freeconLogo from '../../content/assets/freecon-logo.png'


class FreeconWorkshopPage extends React.Component {

  render() {
    return (
      <React.Fragment>
        <SEO title={`Hammock - Freelance Conference Workshop`} />

        <Header />

        <div className="freecon-workshop container">
          <div className="row">
            <div className="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4 freecon-logo-container">
              <img className="freecon-logo" src={freeconLogo} title="FreeCon Hammock Workshop" alt="FreeCon 2019 Hammock Workshop" />
            </div>
            <div className="col-12">
              <h1 className="text-center">Financial Stability for Freelancers</h1>
            </div>
          </div>
          <FreeconWorkshop />
        </div>

        <Footer />
      </React.Fragment>
    )
  }
}

export default FreeconWorkshopPage
